//Colors
$blue: #477CEE;
$lightBlue: #5E93FC;
$lightBlueFooter: #00B4EF;
$grey: #A8A8A8;
$red: #d13131;
$lightred: #ee5b5b;

//Breakpoint;
$mobile-width: 600px;
$mobile-height: 900px;
// $mobile-height: 0;

$desktop-width: 1440px;
$desktop-height: 900px;

$green: #18A48C;
$orange: #de8c3d;
$lightYellow: #fff3cd;
$green25: rgba(24, 164, 140, 0.25);
$purple: #542F61;
$lightPurple: #844797;
$lighterPurple: #B897C4;
$lightPurpleBg: #806589;

$white: #FFFFFF;
$lightWhite: #F7F7F7;
$black: #000000;

$lightGrey: #A5A2A2;
$lightBlack: #2b2b2b;
$loginBg: #F7F7F7;
$darkGrey: #707070
