.pagination-custom .page-link {
    color: #18A48C;
    font-family: YaroCut;
}

.pagination-custom .page-item.active .page-link {
    background-color: #18A48C;
    border-color: #18A48C;
}

.pagination-custom .page-link:hover {
    color: #18A48C;
    cursor: pointer;
}