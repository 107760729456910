@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Light.ttf");
  font-weight: 300;

}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Medium.ttf");
  font-weight: 500;

}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Thin.ttf");
  font-weight: 100;

}

@font-face {
  font-family: MyriadPro;
  src: url("../fonts/MyriadPro-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: MyriadPro;
  src: url("../fonts/MyriadPro-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: MyriadPro;
  src: url("../fonts/MyriadPro-Cond.otf");
  font-weight: 300;

}

@font-face {
  font-family: MyriadPro;
  src: url("../fonts/MyriadPro-Semibold.otf");
  font-weight: 500;

}

@font-face {
  font-family: Marcher;
  src: url("../fonts/HorizonType-Marcher-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: YaroCut;
  src: url("../fonts/VladB-YaroCut-Bold.otf");
  font-weight: 600;
}

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@600&display=swap');

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Light.ttf");
  font-weight: 300;

}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-SemiBold.ttf");
  font-weight: 500;

}