select.form-control.form-control-lg {
    font-size: 12px;
}

.table-pagination .page-link {
    color: #18A48C;
}

.table-pagination .page-item.active .page-link {
    background-color: #18A48C;
    border-color: #18A48C;
}

.react-datepicker-popper {
    z-index: 10 !important;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__header {
    background-color: #18A48C;
    color: white;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__header {
    background-color: #18A48C;
    color: white;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__day-names .react-datepicker__day-name {
    color: white;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__current-month,
.react-datepicker .react-datepicker-time__header,
.react-datepicker .react-datepicker-year-header {
    color: white;
    text-transform: capitalize;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__year-dropdown,
.react-datepicker .react-datepicker__month-dropdown,
.react-datepicker .react-datepicker__month-year-dropdown {
    background-color: #18A48C;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__year-option:hover,
.react-datepicker .react-datepicker__month-option:hover,
.react-datepicker .react-datepicker__month-year-option:hover {
    background-color: #43c7b0;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-selecting-range,
.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__month-text--selected,
.react-datepicker .react-datepicker__month-text--in-selecting-range,
.react-datepicker .react-datepicker__month-text--in-range,
.react-datepicker .react-datepicker__quarter-text--selected,
.react-datepicker .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker .react-datepicker__quarter-text--in-range,
.react-datepicker .react-datepicker__year-text--selected,
.react-datepicker .react-datepicker__year-text--in-selecting-range,
.react-datepicker .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #18A48C;
    color: #fff;
    font-family: Montserrat;
}

.react-datepicker .react-datepicker__day--selected:hover,
.react-datepicker .react-datepicker__day--in-selecting-range:hover,
.react-datepicker .react-datepicker__day--in-range:hover,
.react-datepicker .react-datepicker__month-text--selected:hover,
.react-datepicker .react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__month-text--in-range:hover,
.react-datepicker .react-datepicker__quarter-text--selected:hover,
.react-datepicker .react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__quarter-text--in-range:hover,
.react-datepicker .react-datepicker__year-text--selected:hover,
.react-datepicker .react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__year-text--in-range:hover {
    background-color: #43c7b0;
    font-family: Montserrat;
}

.react-datepicker-wrapper .date-picker-class{
    font-family: Montserrat;
    font-size: 16px;
}

.custom-box-container {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 0px 0px 0px 31px;
    opacity: 1;
    width: 100%;
}


